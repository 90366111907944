import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ThemeToggler } from "gatsby-plugin-dark-mode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoon,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faTwitter,
  faGithub,
  faLinkedin,
  faMastodon
} from "@fortawesome/free-brands-svg-icons";

import "../styles/links.scss";

const socials = [
  {
    name: "Instagram",
    url: "https://www.instagram.com/sudipto_ghosh.99/",
    icon: faInstagram
  },
  {
    name: "Twitter",
    url: "https://twitter.com/ScientificGhosh",
    icon: faTwitter
  },
  {
    name: 'Mastodon',
    url: 'https://m.ghosh.pro/@sudipto',
    icon: faMastodon
  },
  {
    name: "GitHub",
    url: "https://github.com/sudiptog81",
    icon: faGithub
  },
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/sudiptoghosh99/",
    icon: faLinkedin
  }
];

const links = [
  {
    title: "email me",
    url: "mailto:sudipto@ghosh.pro",
    visible: true
  },
  {
    title: "schedule a meet",
    url: "https://cal.com/sudipto/meet",
    visible: true
  },
  {
    title: "cv / resume",
    url: "/Sudipto.Ghosh.CV.pdf",
    visible: true
  },
  {
    title: "qrz: vu3zni",
    url: "https://www.qrz.com/db/VU3ZNI",
    visible: true
  },
  {
    title: "postcrossing",
    url: "https://www.postcrossing.com/user/sudipto",
    visible: true
  },
  {
    title: "there is no earth b",
    url: "https://thereisnoearthb.org/",
    visible: false
  },
  {
    title: "support sana's treatment",
    url: "https://ghosh.pro/upi/?vpa=khanaabida894@okhdfcbank&name=Aabida%20Khan",
    visible: false
  },
  {
    title: "support @thereisnoearthb",
    url: "https://ghosh.pro/upi/?vpa=ddkhera@sbi&name=There%20Is%20No%20Earth%20B",
    visible: false
  }
];

const LinksPage = ({ data }) => (
  <>
    <Helmet>
      <title>{data.site.siteMetadata.title}</title>
    </Helmet>
    <main className="links-container">
      <StaticImage
        src="../images/icon.png"
        alt={data.site.siteMetadata.title}
        placeholder="blurred"
        layout="fixed"
        width={100}
        height={100}
        style={{ marginBottom: "2em" }}
      />

      {/* <h1>{data.site.siteMetadata.title}</h1> */}

      <div className="socials-wrapper">
        {socials.map(social => (
          <a
            key={social.name}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer me"
            className="social-link"
          >
            <FontAwesomeIcon icon={social.icon} />
          </a>
        ))}
      </div>

      <div className="links-wrapper">
        {links.map(link => link.visible ? (
          <a
            key={link.url}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className="link"
            dangerouslySetInnerHTML={{ __html: link.title }}
          >
          </a>
        ) : null)}
      </div>

      <div className="address-wrapper">
        PO Box 4426 Kalkaji HO<br />New Delhi 110019<br />INDIA
      </div>
    </main>

    <ThemeToggler>
      {({ theme, toggleTheme }) => {
        return (
          <div className="toggler">
            <FontAwesomeIcon
              icon={faMoon}
              onClick={e => toggleTheme(theme === "dark" ? "light" : "dark")}
              onKeyDown={e => toggleTheme(theme === "dark" ? "light" : "dark")}
            />
          </div>
        );
      }}
    </ThemeToggler>
  </>
);

export default LinksPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
